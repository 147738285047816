import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import Layout from "../components/layout"
import SEO from "../components/seo"
import VideoList from "../components/videoList"
import CallToAction from "../components/callToAction"
import Footer from "../components/footer"
import VideoHeader from "../components/videoHeader"
import Intro from "../components/intro"

const IndexPage = ({ data: { video, page } }) => {
  const videos = video.nodes
  const latest = videos.slice(0, 3)
  const others = videos.slice(3)

  return (
    <Layout>
      <SEO title={page.title} />
      <Wrapper>
        <VideoHeader title={page.title} />
        {page.topSection.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "paragraph_block" && (
              <Intro title={section.title} text={section.text} />
            )}
          </section>
        ))}
        <VideoList items={latest} />
        {page.middleSection.map(section => (
          <section key={section.id}>
            {section.model.apiKey === "call_to_action_block" && (
              <CallToAction
                title={section.callToAction.title}
                text={section.callToAction.text}
                url={section.callToAction.url}
                cta={section.callToAction.callToAction}
              />
            )}
          </section>
        ))}
        <VideoList items={others} />
      </Wrapper>
      <Footer />
    </Layout>
  )
}

const Wrapper = styled.section`
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: 769px) {
    margin-bottom: ${DesignTokens.spacing[10]};
    }

  }
`

export default IndexPage

export const query = graphql`
  query HomePageQuery {
    page: datoCmsHomePage {
      title
      topSection {
        ... on DatoCmsParagraphBlock {
          id
          title
          text
          model {
            apiKey
          }
        }
      }
      middleSection {
        ... on DatoCmsCallToActionBlock {
          id
          callToAction {
            title
            url
            text
            callToAction
          }
          model {
            apiKey
          }
        }
      }
    }
    video: allDatoCmsVideo(sort: {order: DESC, fields: meta___publishedAt}) {
      nodes {
        title
        guest
        id
        duration
        company
        thumbnail {
          url
          alt
        }
        video {
          providerUid
          thumbnailUrl
          title
          url
        }
      }
    }
  }
`
