import styled from "styled-components"
import DesignTokens from "./designTokens.js"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const LinkButton = styled(OutboundLink)`
  cursor: pointer;
  display: inline-block;
  padding: ${DesignTokens.spacing[3]} ${DesignTokens.spacing[5]};
  border: 1px solid ${DesignTokens.colors.primary[500]};
  text-decoration: none;
  text-transform: uppercase;
  border-radius: ${DesignTokens.borderRadius[0]};
  color: ${DesignTokens.colors.white};
  background: ${DesignTokens.colors.primary[500]};
  font-weight: 900;
  font-size: ${DesignTokens.fontSizes[4]};
  text-align: center;
  transition: all 0.2s ease-in-out;
  &:hover,
  &:active {
    border: 1px solid ${DesignTokens.colors.primary[500]};
    background: ${DesignTokens.colors.white};
    color: ${DesignTokens.colors.primary[500]};
  }
`

const LinkButtonSecondary = styled(LinkButton)`
  color: ${DesignTokens.colors.primary[500]};
  background: ${DesignTokens.colors.secondary[500]};
  &:hover {
    background: ${DesignTokens.colors.secondary[500]};
  }
  &:active {
    background: ${DesignTokens.colors.secondary[500]};
  }
`
const LinkButtonTertiary = styled(LinkButton)`
  background: transparent;
  border: 1px solid ${DesignTokens.colors.white};
  color: ${DesignTokens.colors.white};
  &:hover,
  &:active {
    color: ${DesignTokens.colors.primary[500]};
    background: ${DesignTokens.colors.white};
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  a {
    margin: 0 ${DesignTokens.spacing[2]};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

export { LinkButton, ButtonGroup, LinkButtonSecondary, LinkButtonTertiary }
