import styled from "styled-components"
import DesignTokens from "./designTokens"

const Display = styled.h1`
  text-transform: uppercase;
  font-size: 2rem;
  color: ${ props => props.color || DesignTokens.colors.black};
  line-height: 1;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    font-size: 3rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 3.75rem;
  }
`

const Heading = styled.h2`
  text-transform: uppercase;
  font-size: ${DesignTokens.fontSizes[7]};
  color: ${ props => props.color || DesignTokens.colors.black};
`

export { Display, Heading }
