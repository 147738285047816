import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { ItemsList } from "./utils"
import { Container } from "./utils"
import Video from "./video"

const VideoList = ({ items }) => {
  // console.log(items)
  return (
    <CustomContainer>
      <CustomItemsList>
        {items.map(talk => {
          return (
            <li key={talk.video.providerUid}>
              <Video video={talk} />
            </li>
          )
        })}
      </CustomItemsList>
    </CustomContainer>
  )
}

const CustomContainer = styled(Container)`
  padding-bottom: 0;
`

const CustomItemsList = styled(ItemsList)`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  li {
    width: 100%;
    margin: 0 0 ${DesignTokens.spacing[4]};
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: 768px) {
      margin: 2% 2% 0 0;
      width: calc(98% / 2);
      &:nth-child(-n+2) {
        margin-top: 0;
      }
      &:nth-child(odd) {
        margin-right: 2%;
      }
      &:nth-child(even) {
        margin-right: 0;
      }
    }
    @media screen and (min-width: 1024px) {
      width: calc(96% / 3);
      &:nth-child(-n+3) {
        margin-top: 0;
      }
      &:nth-child(even) {
        margin-right: 2%;
      }
      &:nth-child(3n + 3) {
        margin-right: 0;
      }
    }
  }
`

export default VideoList
