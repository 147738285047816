import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import { LinkButton } from "./button"
import { Heading } from "./typography"

const CallToAction = ({ title, text, url, cta }) => (
  <CustomContainer>
    <Heading>{title}</Heading>
    <Text dangerouslySetInnerHTML={{ __html: text }} />
    <LinkButton href={url}>{cta}</LinkButton>
  </CustomContainer>
)

const CustomContainer = styled(Container)`
  border: 1px solid ${DesignTokens.colors.primary[500]};
  padding: ${DesignTokens.spacing[6]};
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  & > * {
    margin-bottom: ${DesignTokens.spacing[5]};
    &:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 768px) {
  align-items: center;
    padding: ${DesignTokens.spacing[9]};
  }
`

const Text = styled.div`
  p {
    color: ${DesignTokens.colors.tertiary[500]};
    @media screen and (min-width: 768px) {
    text-align: center;
      max-width: 960px;
    }
  }
`

export default CallToAction
