import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import { Heading } from "./typography"

const Intro = ({ title, text }) => (
  <CustomContainer>
    <Heading as="h2">{title}</Heading>
    <Text dangerouslySetInnerHTML={{ __html: text }} />
  </CustomContainer>
)

const CustomContainer = styled(Container)`
  display: flex;
  align-items: flex-start;
  @media screen and (min-width: 768px) {
    align-items: center;
  }
  flex-direction: column;
  & > * {
    margin-bottom: ${DesignTokens.spacing[4]};
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Text = styled.div`
  p {
    font-weight: 400;
    margin-bottom: 0;
    color: ${DesignTokens.colors.black};
    @media screen and (min-width: 768px) {
      text-align: center;
      max-width: 960px;
    }
  }
`

export default Intro
