import React, { useState } from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { PlayCircle } from "react-feather"
import { motion, AnimatePresence } from "framer-motion"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Video = ({ video }) => {
  const [isHover, setIsHover] = useState(false)

  return (
    <Link
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      href={video.video.url}
      backgroundimage={video.thumbnail ? video.thumbnail.url : null}
      target="_blank"
      rel="noopener noreferrer"
    >
      <AnimatePresence>
        {isHover && (
          <Icon
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <PlayCircle size={48} />
          </Icon>
        )}
      </AnimatePresence>
      <Details>
        <Guest>
          {video.guest}
          {video.company && <> - {video.company}</>}
        </Guest>
        <Title>{video.title}</Title>
      </Details>
      <Duration>{video.duration}</Duration>
    </Link>
  )
}

const Link = styled(OutboundLink)`
  display: block;
  position: relative;
  text-decoration: none;
  box-shadow: none;
  color: #fff;
  background-image: ${DesignTokens.colors.overlay.image},
    url(${props => props.backgroundimage});
  background-size: cover;
  background-position: center;
  width: 100%;
  border-radius: ${DesignTokens.borderRadius};
  height: 280px;
  transition: background-image 1s;
`

const Icon = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: ${DesignTokens.colors.overlay.primary};
`

const Details = styled.div`
  padding: ${DesignTokens.spacing[5]};
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Guest = styled(motion.p)`
  width: 90%;
  margin-bottom: ${DesignTokens.spacing[1]};
  font-size: ${DesignTokens.fontSizes[3]};
  line-height: 1.3;
`

const Title = styled.p`
  width: 90%;
  font-size: ${DesignTokens.fontSizes[5]};
  font-weight: 900;
  margin-bottom: 0;
  line-height: 1.3;
`

const Duration = styled.p`
  background: ${DesignTokens.colors.overlay.light};
  padding: ${DesignTokens.spacing[1]} ${DesignTokens.spacing[2]};
  position: absolute;
  display: block;
  bottom: ${DesignTokens.spacing[2]};
  right: ${DesignTokens.spacing[2]};
  font-size: ${DesignTokens.fontSizes[2]};
  border-radius: ${DesignTokens.borderRadius};
`

export default Video
