import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container } from "./utils"
import { Display } from "./typography"
import Header from "./header"
import video from "../images/multi-talks.mp4"

const VideoHeader = ({ title }) => (
  <Wrapper>
    <Video muted autoPlay loop playsInline>
      <source src={video} type="video/mp4" />
    </Video>
    <Overlay>
      <CustomContainer>
        <CustomDisplay color={DesignTokens.colors.white}>{title}</CustomDisplay>
      </CustomContainer>
    </Overlay>
    <Header />
  </Wrapper>
)

const Overlay = styled.div`
  background: ${DesignTokens.colors.overlay.primary};
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Video = styled.video`
  height: 100vh;
  margin-left: -50vh;
  @media screen and (min-width: 768px) {
    width: 100%;
    height: 100%;
    margin-left: 0;
  }
`

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  max-height: 40vh;
  @media screen and (min-width: 768px) {
    max-height: 60vh;
  }
`

const CustomContainer = styled(Container)`
  padding: 3rem 0 0;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0 12rem;
  }
  @media screen and (min-width: 1025px) and (max-width: 1420px) {
    padding: 0 25rem;
  }
  @media screen and (min-width: 1421px) {
    padding: 0 25rem;
  }
`

const CustomDisplay = styled(Display)`
  text-align: center;
`

export default VideoHeader
